::ng-deep {
  .success-snackbar {
    background: var(--kt-success);
    color: white;
    .mat-simple-snackbar-action {
      color: white !important;
    }
  }
  .error-snackbar {
    background: #db4437;
    color: white;
    .mat-simple-snackbar-action {
      color: white !important;
    }
  }
  .warning-snackbar {
    background: var(--kt-warning);
    color: white;
    .mat-simple-snackbar-action {
      color: white !important;
    }
  }
}

table {
  width: 100%;
}

th,
td {
  // min-width: 8rem;
  white-space: nowrap;
  font-size: 13px;
}

.bb-table-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.bb-table-nodata {
  text-align: center;
  margin: 3rem;
}

.bb-table-cpanel {
  display: flex;
  justify-content: space-between;
}

.search-panel {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

@media only screen and (max-width: 1200px) {
  .bb-table-cpanel {
    flex-direction: column;
    row-gap: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .bb-table-header {
    flex-direction: column-reverse;
    row-gap: 1rem;
  }
  .new-btn {
    align-self: flex-end;
  }
}

tr.mat-row,
tr.mat-footer-row {
  height: 40px;
}
.bb-table-body {
  padding: 0;
}

.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1em + 1rem + 2px);
  width: calc(1em + 1rem + 2px);
}

.font-weight-500 {
  font-weight: 500;
}

.fs-16px {
  font-size: 16px !important;
}

.fs-12px {
  font-size: 12px !important;
}

.fs-10px {
  font-size: 10px;
}

.mat-paginator {
  background-color: transparent !important;
}

.mat-drawer-backdrop {
  position: fixed !important;
}

.center {
  display: flex !important;
  justify-content: center !important;
}

table {
  thead {
    border-style: dashed !important;
    tr {
      td,
      th {
        padding: 12px 12px !important;
        font-size: 12px !important;
      }
      &.mat-header-row {
        height: 40px !important;
        font-size: 10px !important;
      }
    }
  }
  tbody {
    tr {
      border-style: dashed !important;
      td,
      th {
        font-size: 12px !important;
        padding: 12px 12px !important;
      }
      &.mat-header-row {
        height: 46px !important;
        font-size: 11px !important;
      }
    }
  }
}

.name-initial {
  width: 32px;
  height: 32px;

  &.name-initial-primary {
    background: #f1faff;
    color: #009ef7;
  }
  &.name-initial-warning {
    background: #fff8dd;
    color: #f1bc00;
  }
  &.name-initial-success {
    background: #e8fff3;
    color: #50cd89;
  }
  &.name-initial-danger {
    background: #fff5f8;
    color: #f1416c;
  }
}

.owl-dt-container {
  width: 100%;
}

::ng-deep {
  .mat-snack-bar-container {
    z-index: 99999999999 !important;
  }
  .success-snackbar {
    background: var(--kt-success);
    color: white;
    .mat-simple-snackbar-action {
      color: white !important;
    }
  }
  .error-snackbar {
    background: #db4437 !important;
    color: white;
    .mat-simple-snackbar-action {
      color: white !important;
    }
  }
}

.aside-dark .menu .menu-item.show > .menu-link {
  background-color: transparent !important;
}

// .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(
//     .border-active
//   ):not(.btn-flush):not(.btn-icon).btn-sm,
// .btn-group-sm
//   > .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(
//     .border-active
//   ):not(.btn-flush):not(.btn-icon) {
//   padding: calc(0.3rem + 1px) calc(1rem + 1px);
// }
